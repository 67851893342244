
import { LoginCallback } from '@okta/okta-react'
import { AuthRole } from 'auth/const'
import ProtectedRoute from 'auth/ProtectedRoute'
import { InternalServerError, Unauthorized } from 'pages/error-pages'
import { RasnDetailsView } from 'pages/rasn-details'
import { RasnOverview } from 'pages/rasn-overview'
import { Routes as ReactRoutes, Route } from 'react-router-dom'

export const Routes = () => (
  <>
    <ReactRoutes>
      <Route path='/login/callback' element={<LoginCallback />} />

      <Route path='/ise' element={
        <InternalServerError />
      } />
      <Route path='/unauthorized' element={
        <Unauthorized />
      } />
    <Route element={<ProtectedRoute role={AuthRole.REPORT_BASIC} />}>
        <Route path={'/'} element={<RasnOverview />} />
    </Route>

    <Route element={<ProtectedRoute role={AuthRole.REPORT_BASIC} />}>
        <Route path={'/rasn/:rasnId'} element={<RasnDetailsView />} />
    </Route>
    </ReactRoutes>
  </>
)
