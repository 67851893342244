import { AppHeader, Avatar, IconButton } from '@nike/eds'
import { NikeApp } from '@nike/nike-design-system-icons'
import { type AuthState } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { Link } from 'react-router-dom'

export function Header () {
  const { authState } = useOktaAuth()

  const getInitials = (authState: AuthState | null) => {
    if (authState) {
      const sub = authState?.accessToken?.claims?.sub

      if (sub) {
        // it would be preferable to use the actual first and last name as
        // reported by the OIDC user info endpoint
        return sub.split('@')[0].split('.').map(part => part[0]).join('').toUpperCase()
      }
    }

    return '-'
  }

  return (
        <AppHeader className='!h-12'
                   id='uninety-admin-header'
                   isDark
                   appName='Uninety Admin'
                   logoSlot={
                       <Link reloadDocument to='/' aria-label='logo-link'>
                           <NikeApp
                               title='Nike logo'
                               color={'white'}
                               width={'56px'}
                               height={'64px'}
                               style={{ marginTop: '6px' }}
                           />
                       </Link>
                   }
                   toolbarSlot={
                       <div></div>
                   }
                   avatarSlot={
                       <>
                           <Avatar initials={getInitials(authState)} size={36} />
                           <IconButton
                               icon="Close"
                               label="quit"
                               variant="secondary"
                               size="small"
                               onClick={() => {
                                 window.dispatchEvent(new Event('logout'))
                               }}
                               className="eds-color--text-secondary"
                           />
                       </>
                   }
        />
  )
}
