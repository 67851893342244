import { type ColumnFiltersState, type PaginationState, type SortingState } from '@tanstack/react-table'
import { type QueryResult, useSearchRasnsQuery } from 'api/rasn'
import { useEffect, useState } from 'react'

export const useRasnData = (pagination: PaginationState, columnFilters: ColumnFiltersState, sorting: SortingState) => {
  const [data, setData] = useState<QueryResult | undefined>(undefined)

  const queryFilters = columnFilters.reduce((acc: any, filter: { id: any, value: any }) => {
    return { ...acc, [filter.id]: filter.value }
  }, {})

  const result = useSearchRasnsQuery({
    page: pagination.pageIndex,
    size: pagination.pageSize,
    sortBy: sorting[0]?.id,
    direction: sorting[0]?.desc ? 'DESC' : 'ASC',
    ...queryFilters
  })

  useEffect(() => {
    if (result.data) {
      setData(result.data)
    }
  }, [result.data])

  return data
}
