import { mainAdminSplitApi } from 'redux/store/mainSplitApi'
import { type Grade, type Rasn, type RasnDetails } from 'types/RASN'

export interface QueryResult {
  results: Rasn[]
  totalCount: number
}

export interface QueryParameters extends Rasn {
  page: number
  size: number
  sortBy: string
  direction: string
}

const rasnApi = mainAdminSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // Partial allows the query parameters to be optional, in which case they
    // are omitted from the request URI (which the backend knows how to handle)
    searchRasns: builder.query<QueryResult, Partial<QueryParameters>>({
      query: (parameters) => {
        return {
          url: '/rasns',
          params: parameters
        }
      },
      providesTags: ['SearchRasns']
    }),
    getRasn: builder.query<RasnDetails, string>({
      query: (rasnNumber) => {
        return {
          url: `/rasns/${rasnNumber}`
        }
      },
      transformResponse (response: RasnDetails): RasnDetails {
        return {
          ...response,
          units: response.units.map((unit) => {
            return {
              ...unit,
              simpleGrade: getSimpleGrade(unit.grade)
            }
          })
        }
      },
      providesTags: (result, error, rasnNumber) => [{ type: 'RasnDetails', id: rasnNumber }]
    }),
    unlockRasn: builder.mutation<void, string>({
      query: (rasnNumber) => {
        return {
          url: `/rasns/${rasnNumber}/unlock`,
          method: 'POST'
        }
      },
      invalidatesTags: (result, error, rasnNumber) => [{ type: 'RasnDetails', id: rasnNumber }, { type: 'SearchRasns' }]
    }),
    closeRasn: builder.mutation<void, string>({
      query: (rasnNumber) => {
        return {
          url: `/rasns/${rasnNumber}/close`,
          method: 'POST'
        }
      },
      invalidatesTags: (result, error, rasnNumber) => [{ type: 'RasnDetails', id: rasnNumber }, { type: 'SearchRasns' }]
    })
  })
})

const getSimpleGrade = (grade: Grade): string => {
  if (typeof grade !== 'string') return ''
  switch (grade) {
    case 'A_GRADE':
      return 'A'
    case 'B_GRADE':
      return 'B'
    case 'C_GRADE':
      return 'C'
    default:
      return grade
  }
}
export const { useSearchRasnsQuery, useGetRasnQuery, useUnlockRasnMutation, useCloseRasnMutation } = rasnApi
