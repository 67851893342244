import { Icon, IconButton, Table, TableCell, TableHeading } from '@nike/eds'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { ConfirmationModal } from 'components/confirmation-modal'
import { useEffect, useMemo, useState } from 'react'

import { ColumnType, Filter } from './Filter'
import { booleanFilterFn, dateFilterFn } from './filters'
import { TablePaging } from './TablePaging'
import { type TableState } from './useTableState'

interface ActionConfig {
  label: string
  icon: string
  show?: (row: any) => boolean
  onClick: (row: any) => void
}

export interface ConfirmationConfig {
  title: string
  message: string
  icon: string | JSX.Element
  confirmText: string
  show?: (row: any) => boolean
  onClick: (row: any) => void
}

export interface TableColumn {
  id: string
  header: string
  type: ColumnType
  enumValues?: string[]
  columnWidth?: number
  initialFilterValue?: any
}

interface TableViewProps {
  columns: TableColumn[]
  data: any[]
  rowCount?: number
  tableState?: TableState
  textFilterDelay?: number
  actions?: ActionConfig[]
  actionsWithConfirmation?: ConfirmationConfig[]
}

/** # Usage
 * The TableView component is a wrapper around the Tanstack Table library to allow for re-usability.
 * By default, the TableView component will handle pagination, filtering, and sorting in the frontend.
 * ## Parameters
 * - columns: An array of column configurations of type `TableColumn`
 * - data: An array of data to be displayed in the table
 * ### TableColumn interface
 * Contains properties which are mapped and used by the TableView component.
 * - id: The key of the data object to be displayed in the column
 * - header: The header of the column\
 * - type: The type of the column, see `ColumnType` enum
 * - enumValues: Only required for `ColumnType.ENUM`, an array of string values to be displayed in the filter dropdown
 * - columnWidth: The width of the column, defaults to 200
 * ### ColumnType enum
 * Decides how the column is displayed and filtered
 * - ENUM: Dropdown filter
 * - TEXT: Text filter
 * - NUMBER: Text filter, right-aligned
 * - DATE: Date picker
 * - BOOLEAN: see `TristateCheckbox`
 * ## Optional parameters:
 * - tableState: Only required for `## Filtering in backend`, see below
 * - textFilterDelay: delay in milliseconds before filtering text columns, defaults to 0 (instant)
 * - actions: icons with actions to be displayed in the "Actions" column, column not initialised if not provided
 * ## Filtering from backend
 * This is handy when you have a large dataset and want to filter data in the backend.
 * You then need to manage the table state outside the TableView component.
 * Use the optional parameter tableState to pass the state to the TableView component, used with useTableState().
 * See "FirstPage.tsx" for an example.
 **/
export const TableView = ({ columns, data, rowCount, tableState, textFilterDelay, actions, actionsWithConfirmation }: TableViewProps) => {
  const [scrolledPastHeading, setScrolledPastHeading] = useState(false)
  const columnHelper = createColumnHelper<any>()

  const actionColumn = actions?.length || actionsWithConfirmation?.length
    ? columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: (table: any) => (
        <div className='flex gap-2'>
          {actions && actions.map(action => {
            const shouldShowAction = action.show ? action.show(table.row.original) : true
            return shouldShowAction
              ? <IconButton
                key={`${action.label}-${String(table.row.id)}`}
                label={action.label}
                variant={'secondary'}
                size='small'
                icon={action.icon}
                onClick={() => { action.onClick(table.row.original) }}
              />
              : null
          })}
          {actionsWithConfirmation && actionsWithConfirmation.map(action => {
            const shouldShowAction = action.show ? action.show(table.row.original) : true
            return shouldShowAction
              ? <ConfirmationModal
                key={`${action.title}-${String(table.row.id)}`}
                onTrigger={() => { action.onClick(table.row.original) }}
                title={action.title}
                message={action.message}
                icon={action.icon}
                iconSize='m'
                iconClass="confirm-icon-button"
                confirmText={action.confirmText}
              />
              : null
          })}
        </div>
      )
    })
    : null

  const renderCellBoolean = ({ row, column }: any) => {
    const value = row.getValue(column.id)
    return value ? <Icon name={'Check'} size='s' /> : ''
  }
  const renderCellDate = ({ row, column }: any) => {
    const value: Date | undefined = row.getValue(column.id)
    if (!value) return ''
    const dateValue = new Date(value)
    return `${dateValue.toLocaleDateString()} ${dateValue.toLocaleTimeString()}`
  }
  const tableColumns = useMemo(() => {
    return [
      ...(actionColumn ? [actionColumn] : []),
      ...columns.map(column => columnHelper.accessor(column.id, {
        header: column.header,
        size: column.columnWidth ? column.columnWidth * 12 : 200,

        ...(column.type === ColumnType.BOOLEAN && {
          filterFn: booleanFilterFn,
          cell: renderCellBoolean
        }),
        ...(column.type === ColumnType.DATE && {
          filterFn: dateFilterFn,
          cell: renderCellDate
        }),
        ...(column.type === ColumnType.NUMBER && {
          meta: {
            align: 'right'
          }
        })
      }))
    ]
  }, [columns, actionColumn])

  useEffect(() => {
    if (columns.some(column => column.initialFilterValue)) {
      columns.filter(column => column.initialFilterValue).forEach(column => {
        table.getAllColumns().forEach(tableCol => {
          if (column.id === tableCol.id) {
            tableCol.setFilterValue(column.initialFilterValue)
          }
        })
      })
    }
  }, [columns])

  const columnTypeMapping = new Map<string, { type: ColumnType, enumValues?: string[] }>(
    columns.map(column => [column.id, { type: column.type, enumValues: column.enumValues }])
  )

  window.onscroll = () => {
    const header = document.getElementById('uninety-admin-header')
    const offsetHeight = header?.offsetHeight ?? 0
    const scrollPosition = window.scrollY || window.pageYOffset
    setScrolledPastHeading(offsetHeight < scrollPosition)
  }

  const table = useReactTable({
    columns: tableColumns,
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // Below is ONLY required when calling backend for filtered/sorted data, based on optional tableState parameter
    ...(tableState && {
      rowCount,
      manualPagination: true,
      manualFiltering: true,
      manualSorting: true,
      onPaginationChange: tableState.setPagination,
      onColumnFiltersChange: tableState.setColumnFilters,
      onSortingChange: tableState.setSorting,
      state: {
        pagination: tableState.pagination,
        columnFilters: tableState.columnFilters,
        sorting: tableState.sorting
      }
    })
  })

  return (
    <div>
      <div className='relative'>
        <Table id='rasn-table' className='w-full mb-2'>
          <thead>
            {scrolledPastHeading && <tr className='h-24' />}
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}
                className={'h-24 eds-background--default' + (scrolledPastHeading ? ' fixed top-0 w-full' : '')}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHeading className='!text-l h-full'
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        minWidth: header.column.columnDef.size,
                        maxWidth: header.column.columnDef.size
                      }}
                    >
                      <div
                        {...{
                          className: `flex flex-col justify-between h-full ${header.column.getCanSort() ? 'cursor-pointer select-none' : ''}`,
                          onClick: header.column.getToggleSortingHandler()
                        }}
                      >
                        <div className="flex-1">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <Icon name='CaretUp' size='s' className='ml-2 mb-1' />,
                            desc: <Icon name='CaretDown' size='s' className='ml-2 mb-1' />
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                        {
                          header.column.getCanFilter()
                            ? (
                              <div className="mt-auto">
                                <Filter
                                  column={header.column}
                                  type={columnTypeMapping.get(header.column.id)?.type ?? ColumnType.TEXT}
                                  textFilterDelay={textFilterDelay ?? 0}
                                  enumValues={columnTypeMapping.get(header.column.id)?.enumValues}
                                  initialValue={columns.find(column => column.id === header.column.id)?.initialFilterValue}
                                />
                              </div>
                              )
                            : null
                        }
                      </div>
                    </TableHeading>
                  )
                })}
              </tr>))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map(row => {
              return <tr key={row.id}>
                {/* TODO: Fix overflow ellipsis, currently cuts off */}
                {row.getVisibleCells().map(cell => (
                  <TableCell key={cell.id}
                    className='!py-1.5 overflow-ellipsis eds-type--body-3'
                    title={String(cell.row.original[cell.column.id])}
                    style={{
                      minWidth: cell.column.columnDef.size,
                      maxWidth: cell.column.columnDef.size
                    }}
                    alignment={(cell.column.columnDef.meta as any)?.align}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </tr>
            })}
          </tbody>
        </Table>
      </div>

      <TablePaging table={table} />
    </div>
  )
}
